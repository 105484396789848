(function($) {
    const cursor = $('.cursor');
      var cursor_move = false;
      $('.contact-big').on('mouseover', (e) => {cursor_move = true;});
      $('.contact-big').on('mouseout', (e) => {
        cursor_move = false;
      });
      $('.contact-big').on('mousemove', (e) => {
        if(cursor_move){
          cursor.css({
            left: e.clientX + 'px', 
            top: e.clientY + 'px'
          })
        }
      });
  $(document).bind('keyup', function (e) {
        if(e.which == 39){
            $('.carousel').carousel('next');
        }
        else if(e.which == 37){
            $('.carousel').carousel('prev');
        }
  });
  $(document).on('mouseover mousemove', '.carousel', function (e) {
    let width = $(this).width();
    if(e.clientX > width / 2){
      $('.carousel-control-next').css('opacity', '1');
      $('.carousel-control-prev').css('opacity', '0');
    } else {
      $('.carousel-control-prev').css('opacity', '1');
      $('.carousel-control-next').css('opacity', '0');
    } 
  });
  $(document).on('mouseout', '.carousel', function (e) {
    $('.carousel-control-next').css('opacity', '0');
    $('.carousel-control-prev').css('opacity', '0');
  });
})(jQuery);
